import Column from '@appbuckets/react-ui/Column';
import Row from '@appbuckets/react-ui/Row';
import * as React from 'react';

import Box from '@appbuckets/react-ui/Box';
import Divider from '@appbuckets/react-ui/Divider';
import Icon from '@appbuckets/react-ui/Icon';


// ---
// Component Render
// ----
const Footer = () => (
  <Box textAlign={'center'} pb={8} mt={8} pt={6} >
    <Divider className={'mb-8'} />
    <Row>
      <Column  >
        <a
          href={'/pdf/PrivacyPolicy.pdf'}
          target={'_blank'}
        >
          Informativa sulla Privacy
        </a>
      </Column>
      <Column>
        <a
          href={'https://form.agid.gov.it/view/aed1814f-b12d-4a1f-8d78-ea6ec88e09e7'}
          target={'_blank'}
        >
          Dichiarazione di accessibilità
          <Icon name={'external-link'} className={'ml-2'} />
        </a>
      </Column>
    </Row>
    <Box className={'mt-6'}>
      <p className={'has-font-bold'}>
        <Icon name={'pen-to-square'}/>
        Valuta Questo Sito:
        <a href={'https://www.ccs.to.it/valuta-sito-bdr'} className={'ml-2'} target={'_blank'} style={{ textDecoration: 'underline' }}>
          RISPONDI AL QUESTIONARIO
        </a>
      </p>
    </Box>
    <Box textColor={'cloud'} mt={6}>
      Made with <Icon name={'heart'} /> by{' '}
      <a target={'_blank'} href={'https://proedis.net'} style={{ color: 'inherit' }}>Proedis S.r.l.</a>
    </Box>
  </Box>
);

Footer.displayName = 'Footer';

export default React.memo(Footer);
